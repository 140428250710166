/*--------------------------------------------------------------
15. Media queries
--------------------------------------------------------------*/
@media only screen and (min-device-width: 1400px) and (max-device-width: 1600px){ 
   

}
@media only screen and (min-width : 1600px) {

}
@media only screen and (max-width: 1600px) {
	.login-box{
	    max-width: 450px;
	}
	.login-btn-sec{
    	padding: 0px 30px;
	}
	.header-div{
    	padding: 0px 20px;
    }
    .content-area{
	    position: relative;
	    padding: 30px 20px;
	}
	.img-icon-list {
    	margin-bottom: 8px;
    }
	.img-icon-list span {
    	margin: 5px 10px;
	}
	.card {
    	border-radius: 25px;
    }
	.card-padding {
    	padding: 20px 15px;
	}
	.header-btn{
    	min-width: initial;
    	padding: 6px 20px 6px 6px;
	}
	.accordion-button {
    	padding: 20px 0px;
    }
    .collapse-icon {
    	top: 20px;
    }
    .accordion-flush .accordion-collapse .accordion-body{
    	padding-bottom: 8px;
	}
	.package-price {
	    font-size: 60px;
	    line-height: 65px;
    }
    .file-action-list{
	    height: 45px;
	    width: 45px;
	    margin-right: 3px;
	}
	.file-action-list img, .file-action-list svg{
	    width: 100%;
	    max-width: 20px;
	    height: auto;
	}
	.min-100vh-relative-div {
    	padding-bottom: 140px;
	}
	.files-tab-div-height{
		/*height: calc(100vh - 330px);*/
		height: calc(100vh - 70px);
	}
	
	.audio-play-div {
	    left: 300px;
	    width: calc(100% - 320px);
	    padding: 25px 50px 12px;
	}
	.play-time {
    	padding: 4px 25px;
    }
    .play-theme-round {
	    height: 55px;
	    width: 55px;
	    min-width: 55px;
    }
    .play-speed {
	    height: 45px;
	    width: 45px;
	    font-size: 13px;
	}

}
@media only screen and (max-width: 1400px) {
	body, p, button, .btn, .form-control, label, .accordion-button, .selectBox .dropdown-menu .dropdown-item {
    	font-size: 15px;
    	line-height: 24px;
    }
    .ft-18, .ft-18-title{
    	font-size: 16px;
    	line-height: 26px;
    }
    .small-title{
	    font-size: 20px;
	    line-height: 30px;
	}
    .profile-list-title {
	    min-width: 90px;
	    padding-right: 5px;
	}
	.files-tab-div-height{
	    		/*height: calc(100vh - 380px);*/
				height: calc(100vh - 20px);
			}
	
}
@media only screen and (max-width: 1200px) {
	.sidebar-div-box{
		width: 300px;
		left: -350px;
		transition: all ease .25s;
		overflow: initial;
	}
	.burger-mobile-view{
		display: block !important;
	}
	.burger-web-view{
		display: none !important;
	}
	.sidebar-div-box.show-sidebar{
		left: 0px;
	}
	.sidebar-header {
    	height: 70px;
    }
    .sidebar-div {
    	padding: 5px 25px 20px;
	    height: calc(100vh - 70px);
    }
    .sidebar-list {
	    padding: 11px 15px;
	    margin-bottom: 5px;
	}
	.main-div {
    	padding-left: 0px;
	}
	.header-btn{
    	padding: 4px 4px;
	}
	.header-btn .round-color-icon {
    	margin-right: 0px;
    }
    .res-header-side-icon{
    	width: 40px;
    	height: auto;
    }
    .header-logo{
    	max-width: 160px;
    	height: auto;
    }
    .header-div{
    	border-bottom: 1px solid #E3E3E3;
    	height: 63.83px;
    }
    .header-div-flex {
    	padding: 10px 0px;
    	border-bottom: none;
    }
    .min-100vh-relative-div {
    	padding-bottom: 120px;
	}
	.files-tab-div-height {
    			/*height: calc(100vh - 320px);*/
				height: 100vh;
	}

	.audio-details-div {
    	margin-top: 15px;
    }
	.audio-play-div {
	    left: 20px;
	    width: calc(100% - 40px);
	    padding: 20px 50px 8px;
	}
	.play-time {
	    padding: 3px 20px;
	    font-size: 15px;
	}
    .play-theme-round {
	    height: 50px;
	    width: 50px;
	    min-width: 50px;
    }
    .audio-play-icons img, .audio-play-icons svg {
    	max-height: 20px;
	}
    .play-speed {
	    height: 40px;
	    width: 40px;
	    font-size: 12px;
	}
	.credit-round-btn {
    	padding: 6px 20px 6px 6px!important;
	}
	.credit-round-btn .round-color-icon {
    	margin-right: 6px;
	}

}

@media only screen and (max-width: 991px) {
 	.hero-bg-img {
	    height: auto;
    }
    .hero-img{
	    max-height: initial;
	}
	.profile-card-hover-div{
		display: none;
	}
	.f-border {
    	border-right: none;
	}
	.feature-text-pad {
    	padding: 8px 5px;
	}
	.f-tag {
	    font-size: 12px!important;
	    line-height: 24px!important;
	    padding: 3px 8px;
	    border-radius: 6px;
	    min-width: initial;
	    width: 100%;
	    max-width: 120px;
    }
    .feature-box {
	    text-align: center;
	    padding: 0px 12px;
	}
	.res-hide-td{
		display: none;
	}
	.res-action-list{
		display: none;
	}
	.res-action-dropdown{
		display: block;
	}
	.ai-chat-div {
	    border-right: none;
	}
	
	.ai-chat-msg-content {
	    overflow-y: auto;
	    padding-right: 10px;
	}
	.ai-chat-msg-box {
	    display: flex;
	    padding-right: 10px;
	}
	.files-tab-div-height {
    			/*height: auto;*/
				height: calc(100vh - 80px);
    }
    .transkript-list-res-height{
		max-height: calc(100vh);
		overflow: auto;
    }
	.web-view{
		display: none !important;
	}
	.mobile-view{
		display: block !important;
	}
	
	.filename {

		max-width: 200px;
	
	  }
	  .aichat-enablecard{
		width: 100%;
	  }
}
@media (max-width: 768px) {  
    .time-speaker-container {
        display: flex;
        justify-content: start;
        align-items: center;
    }

}

@media (min-width: 992px){
	.file-session-tab-bar{
		 margin-bottom: -44px;
		 z-index: 1;
		 position: relative;
	}
	.chat-message {
	    font-size: medium
	}
}

@media (max-width: 992px){
	.file-session-tab-bar, .file-session-tab-bar-workspace{
		 margin: 0 auto;
		 margin-bottom: 10px;
	}

	
}

@media only screen and (max-width: 767px) {
	.mini-title {
    	font-size: 22px;
    	line-height: 32px;
    }
    .medium-title {
    	font-size: 23px;
    	line-height: 25px;
    }
    .small-title{
	    font-size: 20px;
	    line-height: 28px;
	}
    .sec-pad-top{
    	padding-top: 30px;
	}
	.text-switch .btn-toggle {
	    margin: 0 4.5rem;
	    width: 45px;
	}
	.text-switch .btn-toggle .handle {
	    top: -7px;
	    width: 26px;
	    height: 26px;
	}
	.text-switch .btn-toggle.active .handle {
	    left: 19px;
	}
	.package-price {
	    font-size: 52px;
	    line-height: 60px;
	}
	.transkript-list {
    	border-radius: 4px;
    	display: block;
    	padding: 6px 5px;
    }
	.transcript-dropdown, .transcript-dropdown .dropdown-toggle {
	    width: 95px;
	    display: inline-block; 
	}
	.transcript-dropdown .dropdown-toggle {
	    font-size: 12px;
	    line-height: 20px;
	    border-radius: 6px;
	    padding: 3px 8px;
	}
	.transcript-time {
	    min-width: 80px;
	    padding: 0px 8px;
	    font-size: 12px;
	    display: flex;
		justify-content: center;
    }
    .transcript-content {
    	font-size: 12px;
	    line-height: 20px;
	    margin-top: 3px;
	}
}
@media only screen and (max-width: 640px) {
  

}
    

@media only screen and (max-width: 575px) {
	.content-area {
    	padding: 20px;
	}
	.card-padding {
    	padding: 10px;
	}
	.border-dashed-box{
    	padding: 10px;
	}
	.theme-btn {
    	padding: 9px 22px;
	}
	.btn-icon {
    	margin-right: 5px;
    }
	.header-btn {
    	margin-right: 5px;
	}
	.header-minute-text {
	    padding-right: 10px;
	    margin-right: 10px;
	    white-space: initial;
	}
	.accordion-item {
    	padding-left: 20px;
	}
    .modal-body, .modal-body-padding {
    	padding: 30px 15px;
	}
	.btn-close {
    	right: 12px;
    	top: 12px;
    }
    .icon-tab.nav-pills .nav-link{
	    height: 55px;
	    min-width: initial;
	}
	.table-serach{
	    width: 100%;
	    max-width: 250px;
	}
	
	.ai-chat-msg-box .theme-btn {
	    font-size: 13px;
	    padding: 9px 14px;
	}
	.water-slider.text-slider .range-slider__range {
	  height: 8px;
	}
	.water-slider.text-slider .range-slider__range::-webkit-slider-thumb {
	  width: 24px;
	  height: 24px;
	}
	.water-slider.text-slider .range-slider__range::-moz-range-thumb {
	  width: 24px;
	  height: 24px;
	}
	.min-100vh-relative-div {
    	padding-bottom: 110px;
	}
	.audio-details-div {
    	margin-top: 10px;
    }
	.audio-play-div {
	    left: 0px;
	    width: 100%;
	    padding: 18px 18px 8px;
	    border-radius: 20px 20px 0px 0px;
	}
	.audio-play-icons {
	    margin: 0px 15px;
	}
	.play-time {
	    padding: 1px 15px;
    	font-size: 12px;
	}
    .play-theme-round {
	    height: 45px;
	    width: 45px;
	    min-width: 45px;
	    margin: 0px 15px;
    }
    .audio-play-icons img, .audio-play-icons svg {
    	max-height: 16px;
	}
    .play-speed {
	    height: 38px;
	    width: 38px;
	    font-size: 11px;
	    line-height: 13px;
	}
}
@media (min-width: 576px){

}

@media only screen and (max-width: 480px) {
	.sidebar-div {
		justify-content: flex-start;
	}
}


@media only screen and (max-width: 400px) {

}
@media screen and (max-width: 375px){

}
@media screen and (max-width: 340px){
	.sidebar-div-box{
		width: 270px;
	}
	.header-logo {
    	max-width: 132px;
    }
    .header-div{
    	height: 53.63px;
    }
    .res-header-dash-sticky {
    	top: 53.63px;
    }
    .res-header-side-icon {
	    min-width: 30px;
	    width: 30px;
    }
    .nav-pills.btn-pills .nav-link {
	    padding: 5px 14px;
	    margin-right: 5px;
    }
    .file-action-list {
    	margin-right: 0px;
	}
}






